<template>
  <b-card class="card-cs-border card-cs-bg mb-0 mt-2">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h6 class="mb-0 mr-2">{{ invite.nome }}</h6>
        <b-badge
          :variant="`light-${inviteStatusVariant.variant}`"
          :class="`border-${inviteStatusVariant.variant}`"
          pill
        >
          {{ inviteStatusVariant.label }}
        </b-badge>
      </div>
      <div class="d-flex align-items-center">
        <b-badge
          class="border-warning card-cs-bg mr-1 cursor-pointer"
          :id="`copyTooltip-${invite.id}`"
          @click="copyLink()"
        >
          <feather-icon icon="CopyIcon" class="mr-50 text-warning" />
          <span class="text-warning"> Link de cadastro </span>
          <b-tooltip
            ref="tooltip"
            triggers="click"
            :target="`copyTooltip-${invite.id}`"
            title="Copiado!"
            variant="primary"
            :delay="{ show: 100, hide: 500 }"
          />
        </b-badge>

        <b-badge class="border-warning card-cs-bg cursor-pointer" @click="deleteInvite">
          <feather-icon icon="Trash2Icon" class="mr-50 text-warning" />
          <span class="text-warning"> Excluir convite </span>
        </b-badge>
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-between">
      <div class="d-flex">
        <div class="d-flex flex-column mr-2">
          <span> Último envio </span>
          <span class="text-dark"> {{ invite.ultimo_envio | formatDate }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Telefone </span>
          <span class="text-dark"> {{ invite.telefone }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> CPF </span>
          <span class="text-dark"> - </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Email </span>
          <span class="text-dark"> {{ invite.email }} </span>
        </div>
      </div>
      <b-button variant="outline-primary" class="bg-white text-dark" @click="sendInvite">
        <span class="font-weight-bolder">
          {{ invite.ultimo_envio ? 'Reenviar e-mail' : 'Enviar e-mail' }}
        </span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BBadge, BButton, BTooltip, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'InviteCard',
  components: {
    BCard,
    BBadge,
    BButton,
    BTooltip,
  },
  filters: {
    formatDate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    invite: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deleteInvite() {
      this.$swal
        .fire({
          title: 'Tem certeza?',
          text: 'Você não poderá reverter essa ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Não, cancelar!',
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await this.$store.dispatch('provider/deleteInvite', this.invite.id)
              this.$swal.fire({
                title: 'Convite excluído com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$emit('inviteDeleted')
            } catch (error) {}
          }
        })
    },
    async sendInvite() {
      this.$swal
        .fire({
          title: 'Tem certeza?',
          text: `Você está ${
            this.invite.ultimo_envio ? 'reenviando' : 'enviando'
          } o convite para o usuário!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Não, cancelar!',
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await this.$store.dispatch('provider/sendInvite', this.invite.id)
              this.$swal.fire({
                title: 'Convite enviado com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$emit('inviteSent')
            } catch (error) {}
          }
        })
    },
    statusBadge(status) {
      const statuses = {
        active: {
          variant: 'primary',
          text: 'Ativo',
        },
        inactive: {
          variant: 'warning',
          text: 'Inativo',
        },
        tested: {
          variant: 'info',
          text: 'Testado',
        },
      }

      return statuses[status]
    },
    copyLink() {
      this.$copyText(this.invite.url_convite).then((e) => {
        setTimeout(() => this.$refs.tooltip.$emit('close'), 750)
      })

      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 750)
    },
  },
  computed: {
    inviteStatusVariant() {
      let variant = {
        variant: 'primary',
        label: 'Ativo',
      }

      if (this.invite.aceito) {
        variant = {
          variant: 'success',
          label: 'Cadastro realizado',
        }
      } else {
        variant = {
          variant: 'warning',
          label: 'Aguardando cadastro',
        }
      }
      return variant
    },
  },
}
</script>
