<template>
  <b-card class="card-cs-border">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">Usuários cadastrados</h3>
      <b-button
        variant="primary"
        class="d-flex align-items-center"
        @click="showCreateInviteForm = true"
      >
        <span>Convidar usuário</span>
        <feather-icon icon="PlusIcon" size="20" class="ml-1" />
      </b-button>
    </div>
    <b-card class="card-cs-border card-cs-bg mb-0 mt-2" v-if="showEmptyState">
      <div class="d-flex justify-content-start align-items-center p-1">
        <b-img src="@/assets/images/admin/invites-empty-state.svg" />
        <div class="d-flex flex-column justify-content-start ml-2">
          <h3>Nenhum usuário convidado até o momento.</h3>
          <p>
            Usuários convidados terão autonomia para administrar as mesmas informações que você.
          </p>
        </div>
      </div>
    </b-card>
    <invite-form v-if="showCreateInviteForm" @closeForm="updatePage" />
    <template v-for="invite in invites">
      <invite-card :invite="invite" @inviteDeleted="updatePage" @inviteSent="updatePage" />
    </template>
    <template v-for="user in users">
      <user-card :user="user" />
    </template>
  </b-card>
</template>

<script>
import { BImg, BCard, BButton } from 'bootstrap-vue'
import InviteForm from './InviteForm.vue'
import InviteCard from './InviteCard.vue'
import UserCard from './UserCard.vue'
import ProfileForm from './ProfileForm.vue'

export default {
  name: 'CreatedUsersList',
  components: {
    BImg,
    BCard,
    BButton,
    UserCard,
    InviteCard,
    InviteForm,
    ProfileForm,
  },
  data() {
    return {
      users: [],
      invites: [],
      showCreateInviteForm: false,
      showEditUserForm: false,
    }
  },
  async mounted() {
    await this.fetchUsers()
    await this.fetchInvites()
  },
  methods: {
    async fetchUsers() {
      const { data } = await this.$store.dispatch('provider/currentEconomicGroup')
      this.users = data.data.users
    },
    async fetchInvites() {
      const { data } = await this.$store.dispatch('provider/fetchInvites')
      this.invites = data.data
    },
    updatePage() {
      this.showCreateInviteForm = false
      this.fetchInvites()
      this.fetchUsers()
    },
  },
  computed: {
    showEmptyState() {
      return !this.users.length && !this.invites.length && !this.showCreateInviteForm
    },
  },
}
</script>
