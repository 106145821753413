<template>
  <b-card class="card-cs-border card-cs-bg mb-0 mt-2">
    <div class="d-flex justify-content-between align-items-end">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column mr-2">
          <span> Nome </span>
          <span class="text-dark font-weight-bolder"> {{ user.nome }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Celular </span>
          <span class="text-dark font-weight-bolder"> {{ user.telefone }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> CPF </span>
          <span class="text-dark font-weight-bolder"> {{ user.cpf }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span> Email </span>
          <span class="text-dark font-weight-bolder"> {{ user.email }} </span>
        </div>
      </div>
      <b-button
        variant="outline-primary"
        class="bg-white text-dark"
        @click="$emit('showEditForm')"
        v-if="$props.editable"
      >
        <span class="font-weight-bolder"> Editar </span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BButton } from 'bootstrap-vue'

export default {
  name: 'UserCard',
  components: {
    BCard,
    BButton,
    BCardBody,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>
